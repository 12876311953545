import React from "react"
import { Helmet } from "react-helmet"
import { GlobalStateContext } from "../components/globalState.js"
import View from "../components/view.js"
import "../components/layout.css"
import 'typeface-open-sans/index.css'
import { exitFullScreen } from "../util/fullScreenHelpers.js"
import Footer from "../components/footer.js"
//import theme from "../theme.yaml"

class PaginatedGalleryTemplate extends React.Component {

    componentDidMount() {
        exitFullScreen()
    }

    render() {

        const highlight = (this.props.location && this.props.location.state ? this.props.location.state.highlight : -1)
        return (<>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Brodie Kokoska - Koko Ventures Corp., Krave Media Group Corp., SalesBinder</title>
                    <meta data-react-helmet="true" name="description" content="Entrepreneur, Technology Geek. Founder of SalesBinder."/>
                </Helmet>
                <div style={{ padding: "46px 26px 32px 26px", textAlign: "center" }}>
                    <p style={{ color: "#fff", fontSize: "40px", padding: "0px", margin: "0" }}>Brodie Kokoska</p>
                    <p style={{ color: "#999", fontSize: "18px", fontWeight: "300", padding: "0px", marginTop: "10px", marginBottom: "30px" }}>Entrepreneur & Technology Geek.</p>
                    <a href="https://www.salesbinder.com" style={{ color: "#ccc", backgroundColor: "#222", display: "inline-block", borderRadius: "16px", fontSize: "14px", fontWeight: "200", padding: "4px 16px", marginRight: "16px", marginBottom: "10px", letterSpacing: "0.05em" }}>SalesBinder - Inventory Software</a>
                    <a href="https://kokocorp.ca" style={{ color: "#ccc", backgroundColor: "#222", display: "inline-block", borderRadius: "16px", fontSize: "14px", fontWeight: "200", padding: "4px 16px", marginRight: "16px", marginBottom: "10px", letterSpacing: "0.05em" }}>Koko Ventures Corp.</a>
                    <a href="https://www.kravegroup.com" style={{ color: "#ccc", backgroundColor: "#222", display: "inline-block", borderRadius: "16px", fontSize: "14px", fontWeight: "200", padding: "4px 16px", marginRight: "16px", marginBottom: "10px", letterSpacing: "0.05em" }}>Krave Media Group Corp.</a>
                </div>
                <GlobalStateContext.Consumer>
                    {globalState => (
                        <>
                            <View
                                globalState={globalState}
                                pageContext={this.props.pageContext}
                                highlight={highlight}
                            />

                            <Footer />
                        </>

                        
                    )}
                </GlobalStateContext.Consumer>
        </>)
    }
}

export default PaginatedGalleryTemplate